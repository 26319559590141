<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'ocm.general.about' | translate }} Kaayo</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col size="2" [ngStyle]="{    height: '52%', width: '80%', marginLeft: '10px'}">
        <img src="assets/images/icons/branding/logo2.webp">
      </ion-col>
      <ion-col>
      </ion-col>
    </ion-row>
  </ion-grid>
  <p>
      <strong>Kaayoo is the worlds largest Open Data registry of electric vehicle charging locations.</strong> By adding and editing the information within Kaayoo you are improving the data for an unlimited amount of other apps and services which can use the data for free.
  </p>

  <p>
      <a href="https://www.kaayoo.com/en/" target="_blank">kaayoo.com</a>
  </p>

  <p>Join our community discussions: <a href="https://www.kaayoo.com/en/" target="_blank">community.kaayoo.com</a>
  </p>
  <p>Software development and service hosting by <a href="https://www.kaayoo.com/en/" target="_blank">kaayoo.com</a>
  </p>
  <p>Our service is run by volunteers, our data is largely crowdsourced by you. Some data is imported from public Open Data sources.</p>
  <p>Network operators can <a href="https://www.kaayoo.com/en/site/about/datasharing" target="_blank">provide us with an OCPI data feed</a> to freely promote their network and ensure information accuracy. </p>

  <ion-note>
    <p>App Version: 1.0.2</p>

  </ion-note>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="start"></ion-icon>
        Close

      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>
