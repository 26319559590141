<ion-header>
	<ion-toolbar>

		<ion-title>
			Route Planner
		</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()">
				<ion-icon name="close" slot="end"></ion-icon>
				Close
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content class="route-planner ion-padding">
	<route-planner></route-planner>
</ion-content>