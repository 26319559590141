import { environment } from "../../environments/environment";

/**
* @author Christopher Cook
* @copyright Webprofusion Pty Ltd https://webprofusion.com
*/

export class AppConfig {
  public baseURL: string;
  public loginProviderRedirectBaseURL: string;
  public loginProviderRedirectURL: string;
  public enableLiveMapQuerying: boolean;
  public googleMapsAPIKey: string;
  public enableStaticMaps: boolean;

  constructor() {
    //this.baseURL = "https://map.openchargemap.io"; // ocm prod
    //this.baseURL = "http://localhost:8100"; // local/dev
    this.baseURL = "http://mapapp.o2run.com"; //o2run prod
    //this.loginProviderRedirectBaseURL = "https://openchargemap.org/site/loginprovider/?_mode=silent&_forceLogin=true&_redirectURL=";
    //this.loginProviderRedirectBaseURL = "http://localhost:5078/loginprovider/?_mode=silent&_forceLogin=true&_redirectURL=";
    this.loginProviderRedirectBaseURL = "http://map.o2run.com/loginprovider/?_mode=silent&_forceLogin=true&_redirectURL=";
    //  this.loginProviderRedirectBaseURL = "http://localhost:8100/loginprovider/?_mode=silent&_forceLogin=true&_redirectURL=";
    this.loginProviderRedirectURL = this.loginProviderRedirectBaseURL + this.baseURL;
    this.enableLiveMapQuerying = true;
    this.googleMapsAPIKey = environment.googleMapsKey;

    this.enableStaticMaps = environment.enableStaticMaps;
  }
}