<ion-list>
  <ion-item *ngFor="let p of poiList" style="max-width:400px;">
    <ion-grid>
      <ion-row>
        <ion-col>
          {{p.AddressInfo.Title}}
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="3">
          <p>
            <img [src]="getIconForPOI(p)" style="width:24px"/>

          </p>
          <ion-note>
            {{getFormattedDistance(p)}}
          </ion-note>
          <ion-button *ngIf="enableCopyOption" size="small" (click)="onCopyCommand(p)">Copy</ion-button>
          <ion-button *ngIf="enableEditOption" size="small" (click)="onEditCommand(p)">Edit</ion-button>
        </ion-col>
        <ion-col>
          <ion-row>
            <ion-col>
              <p>
                {{getFormattedAddress(p)}}
              </p>
              <ion-note>
                {{getFormattedConnectorList(p)}}
              </ion-note>
            </ion-col>

          </ion-row>
          <ion-row>
            <ion-col>
              {{p.UsageType?.Title}}
            </ion-col>
            <ion-col>
              {{p.OperatorInfo?.Title}}
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

    </ion-grid>

  </ion-item>
</ion-list>