import { MappingAPI } from "../app/services/mapping/interfaces/mapping";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
    name: 'dev',
    version: '8.5.7-dev',
    production: false,
    analyticsId: '',
    googleMapsKey: '',
    mapBoxToken: 'pk.eyJ1Ijoibml0dGk1MTB4IiwiYSI6ImNsc20zeTdndTA1c2oya3FyMnZsdmNxZnUifQ.mjAKPaulY2DnpVOCE4_rbw',
    mapKitToken: '',
    enableStaticMaps: false,
    defaultMapProvider: 4, // 1: GM native 4: MapBox // 5:MappingAPI.MAPKIT_JS  //6: mapbox GL with Maptiler,
    //apiBase: 'http://localhost/OCM.Web/site',
    //apiBase: 'https://o2run-ocm-api-prod.azurewebsites.net',
    apiBase: 'https://api-01.openchargemap.io',
    //apiBase: 'http://localhost:5143',
    //development
    //apiKey: '1d192491-c085-4563-9dbb-b1f09a2e9c66', 
    //production:
    apiKey: '9bb03e5b-0fb2-4916-9b2b-26c6bd27a56a',

    enabledFeatures: ['MAP', 'ADD_COMMENT', 'ADD_PHOTO', 'FAVOURITES', 'FILTER_OPTIONS_BY_COUNTRY', 'ADD_POI', 'EDIT_POI', 'LAYERS']
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
