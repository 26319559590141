<ion-header>
  <ion-toolbar>
    <ion-title>
      Share Photo
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="media-upload">

  <div class="ion-padding">
    <div class="banner">
      <h1>{{poi.AddressInfo.Title}}</h1>
      <p>{{poi.AddressInfo.AddressLine1}}</p>
    </div>
    <p>You can upload a photo from your device or take a new photo:
    </p>

    <div class="upload">
      <ion-button (click)="inputFile.click()">
        <ion-icon name="camera" aria-label="camera" slot="start"></ion-icon>
        <ion-label>Choose Photo..</ion-label>
      </ion-button>
      <input type="file" #inputFile required id="img-upload-media" (change)="loadCameraOrLibraryImage()"/>
    </div>

    <canvas id="img-upload-canvas" style="display:none;width:500;height:500;border:1px solid red;"></canvas>
    <ion-card *ngIf="imgData!=null">
      <ion-toolbar>
        <ion-title slot="start">Preview</ion-title>
        <ion-buttons slot="end">

          <ion-button (click)="rotateImage()">
            <ion-icon name="refresh" slot="start"></ion-icon>
            <ion-label>Rotate</ion-label>
          </ion-button>

        </ion-buttons>

      </ion-toolbar>
      <ion-card-content>

        <ion-list>

          <ion-item>
            <ion-label position="floating">Comment</ion-label>
            <ion-input type="text" [(ngModel)]="comment" placeholder="(optional comment)"></ion-input>

          </ion-item>

        </ion-list>
        <img id="preview" style="max-width:500px"/>
      </ion-card-content>
    </ion-card>

  </div>

</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="secondary">
      <ion-button (click)="cancel()">
        <ion-icon name="close" slot="start"></ion-icon>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button (click)="performUpload()">
        Upload
        <ion-icon name="send" slot="end"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>