<div class="route">
  <div class="route-places">
    <ion-list>
      <ion-list-header>
        <ion-icon name="map" slot="start"></ion-icon>
        <ion-label>Plan Your Journey</ion-label>
      </ion-list-header>

      <ion-item *ngIf="routeStartPlace==null">
        <ion-icon name="ionic" item-left></ion-icon>
        <ion-searchbar [(ngModel)]="routeStart" (ionInput)="placeSearchRouteStart.getPlacesAutoComplete($event,'routeStart')" (ionCancel)="onPlaceSearchCancel($event)"
          placeholder="Starting Point" [debounce]="500"></ion-searchbar>

      </ion-item>
      <ion-item *ngIf="routeStartPlace!=null" (click)="clearRouteStart()">
        <ion-icon name="ionic" item-left></ion-icon>

        <h2>{{placeSearchRouteStart.selectedPlace?.name}}</h2>
      </ion-item>

      <ion-item *ngIf="routeDestinationPlace==null">
        <ion-icon name="pin" item-left></ion-icon>
        <ion-searchbar [(ngModel)]="routeDestination" (ionInput)="placeSearchRouteDestination.getPlacesAutoComplete($event,'routeDestination')"
          (ionCancel)="onPlaceSearchCancel($event)" placeholder="Destination" [debounce]="500"></ion-searchbar>

      </ion-item>
      <ion-item *ngIf="routeDestinationPlace!=null" (click)="clearRouteDestination()">
        <ion-icon name="pin" item-left></ion-icon>
        <h2>{{placeSearchRouteDestination.selectedPlace?.name}}</h2>
      </ion-item>

    </ion-list>

    <ion-toolbar primary>
      <ion-buttons slot="secondary">
        <ion-button (click)="clearRoute()" *ngIf="isRouteSet">
          <ion-icon name="close" slot="start"></ion-icon>
          Clear Route

        </ion-button>
      </ion-buttons>
      <ion-buttons slot="primary">
        <ion-button (click)="showSettings()" *ngIf="!advancedSettingsMode">
          Show Settings
          <ion-icon name="cog" slot="end"></ion-icon>
        </ion-button>
        <ion-button (click)="hideSettings()" *ngIf="advancedSettingsMode">
          Hide Settings
          <ion-icon name="cog" slot="end"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <place-search #placeSearchRouteStart (placeChanged)="routeStartSelected($event)"></place-search>

  <place-search #placeSearchRouteDestination (placeChanged)="routeDestinationSelected($event)"></place-search>

  <p class="ion-padding" *ngIf="routeCalcInProgress">
    <ion-spinner class="ion-padding"></ion-spinner> Calculating Route..
  </p>

  <ion-list *ngIf="selectedJourneyRoute!=null">
    <ion-list-header>
      Total Distance {{selectedJourneyRoute.TotalDistanceKM | number:'1.0-2'}} km, {{formatDuration(selectedJourneyRoute.TotalDurationMinutes)}},
      {{selectedJourneyRoute.TotalEnergykWh |number:'1.0-2'}} kWh
    </ion-list-header>

  </ion-list>

  <div *ngIf="advancedSettingsMode">

    <ion-list>
      <ion-list-header>
        <ion-icon name="cog"></ion-icon> Route Settings
      </ion-list-header>
      <ion-item>
        <ion-label position="stacked">Search Distance ({{routeSearchDistance}})</ion-label>
        <ion-range min="1" max="200" step="5" snaps="true" pin="true" [(ngModel)]="routeSearchDistance" light>
          <ion-icon slot="start" small name="map"></ion-icon>
          <ion-icon slot="end" large name="map"></ion-icon>
        </ion-range>

      </ion-item>
      <ion-item>
        <ion-label position="stacked">Energy Efficiency (kWh Per km)</ion-label>
        <ion-input type="number" min="0.1" max="1" [(ngModel)]="kWhPerKM"></ion-input>

      </ion-item>
    </ion-list>
  </div>
</div>