<ion-header>
    <ion-toolbar>
        <ion-title>
            {{'ocm.navigation.profile.sectionTitle' | translate}}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="profile-page ion-padding">

    <div *ngIf="userProfile==null">
        You are not signed in. <button ion-button (click)="showSignInModal()">{{'ocm.general.signIn' | translate}}</button>
    </div>
    <div *ngIf="userProfile!=null">

        <ion-card>

            <ion-item>
                <ion-avatar slot="start">
                    <img [src]="userProfile.ProfileImageURL || 'assets/default-avatar.png'">
                </ion-avatar>
                <h2>{{userProfile.Username || ''}}</h2>
            </ion-item>

            <ion-card-content>
                <p>Name: {{userProfile.Username || ''}}</p>
                <p>Date Joined: {{userProfile.DateCreated || ''}}</p>
                <p>Bio: {{userProfile.Profile || ''}}</p>
                <p>Location: {{userProfile.Location || ''}}</p>
                <p>Website: <a *ngIf="userProfile.WebsiteURL" [href]="userProfile.WebsiteURL">{{userProfile.WebsiteURL}}</a></p>
            </ion-card-content>

            <ion-card-content>
                <p><strong>Community Stats</strong></p>
                <p>{{userProfile.ReputationPoints || 0}} Community Points</p>
            </ion-card-content>

            <ion-card-content>
                <p><strong>Other Account Details</strong></p>
                <p>Identity Provider: OCM</p>
                <p>Identifier: {{userProfile.Username || ''}}</p>
                <p>Date Last Signed In: {{userProfile.DateLastLogin || ''}}</p>
                <p>Email Address (not public): {{userProfile.EmailAddress || ''}}</p>
            </ion-card-content>

        </ion-card>

    </div>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon name="close" slot="start"></ion-icon>
                Close
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
