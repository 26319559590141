<ion-app>
    <app-header-component></app-header-component>
    <!-- Main Content -->
    <ion-split-pane [disabled]="true" contentId="main-content">
        <ion-menu contentId="main-content" swipeGesture="false">
            <ion-content>
                <ion-list>
                    <ion-menu-toggle auto-hide="false">

                        <ion-item [routerDirection]="'root'" [routerLink]="['/search']">
                            <ion-icon slot="start" name="home"></ion-icon>
                            <ion-label>
                                {{ 'ocm.search.sectionTitle' | translate }}
                            </ion-label>
                        </ion-item>

                        <ion-item (click)="openRoutePlannerModal()" *ngIf="enabledFeatures.includes('ROUTE_PLANNER')">
                            <ion-icon slot="start" name="map"></ion-icon>
                            <ion-label>
                                Route Planner
                            </ion-label>
                        </ion-item>

                        <ion-item (click)="add()" *ngIf="enabledFeatures.includes('ADD_POI')">
                            <ion-icon slot="start" name="add-circle-outline"></ion-icon>
                            <ion-label>
                                {{ 'ocm.general.addLocation' | translate }}
                            </ion-label>
                        </ion-item>

                        <ion-item (click)="journeys()" *ngIf="enabledFeatures.includes('FAVOURITES')">
                            <ion-icon slot="start" name="map"></ion-icon>
                            <ion-label>
                                Journeys
                            </ion-label>
                        </ion-item>

                        <ion-item (click)="addLayer()" *ngIf="enabledFeatures.includes('LAYERS')">
                            <ion-icon slot="start" name="albums"></ion-icon>
                            <ion-label>
                                Add Layer
                            </ion-label>
                        </ion-item>

                        <ng-container *ngIf="!isUserAuthenticated()">
                            <ion-item (click)="signIn()">
                                <ion-icon slot="start" name="person"></ion-icon>
                                <ion-label>
                                    {{'ocm.general.signIn' | translate }}
                                </ion-label>
                            </ion-item>
                        </ng-container>

                        <ng-container *ngIf="isUserAuthenticated()">

                            <ion-item (click)="profile()">
                                <ion-icon slot="start" name="person"></ion-icon>
                                <ion-label>
                                    {{'ocm.navigation.profile.sectionTitle' | translate }}
                                </ion-label>
                            </ion-item>
                            <ion-item (click)="signOut()">
                                <ion-icon slot="start" name="log-out"></ion-icon>
                                <ion-label>
                                    {{'ocm.general.signOut' | translate }}
                                    <br>
                                    <small style="color:#c0c0c0">
                                        {{getUserName()}}
                                    </small>
                                </ion-label>
                            </ion-item>

                        </ng-container>

                        <ion-item (click)="about()">
                            <ion-icon slot="start" name="information-circle-outline"></ion-icon>
                            <ion-label>
                                {{ 'ocm.general.about' | translate }}
                            </ion-label>
                        </ion-item>

                    </ion-menu-toggle>
                </ion-list>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
