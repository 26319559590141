<ion-item *ngIf="useFilteredOperators && !selectedOperator">
  <ion-label position="floating">Network Operator</ion-label>
  <ion-select [(ngModel)]="operatorId" (ionChange)="onOperatorChange()">
    <ion-select-option *ngFor="let i of operators" [value]="i.ID">{{
      i.Title
    }}</ion-select-option>
    <ion-select-option value="" *ngIf="useFilteredOperators"
      >Other...</ion-select-option
    >
  </ion-select>
</ion-item>

<ion-item *ngIf="!useFilteredOperators && !selectedOperator">

  <ion-searchbar
    placeholder="Search network operators"
    showCancelButton="focus"
    (ionCancel)="cancelOperatorLookup()"
    autocomplete="on"
    [(ngModel)]="operatorSearchKeyword"
    (ionInput)="searchOperators($event)"
  ></ion-searchbar>
</ion-item>

<ng-container *ngIf="operatorSearchResults.length > 0">
  <ion-list>
    <ion-item
      *ngFor="let item of operatorSearchResults"
      (click)="onOperatorChange(item)"
    >
      <ion-avatar item-left>
        <ion-icon name="card"></ion-icon>
      </ion-avatar>
      <ion-label>
        <p>{{ item.Title }}</p>
        <p>{{ item.WebsiteURL }}</p>
      </ion-label>
    </ion-item>
  </ion-list>
</ng-container>

<ng-container *ngIf="selectedOperator != null">
  <ion-item>
    <ion-avatar item-left>
      <ion-icon name="locate"></ion-icon>
    </ion-avatar>
    <ion-label>
      <p>{{ selectedOperator.Title }}</p>
      <p>{{ selectedOperator.WebsiteURL }}</p>
      <ion-button (click)="selectedOperator = null">Change</ion-button>
    </ion-label>
  </ion-item>
</ng-container>

<ng-container *ngIf="operatorList?.length > 0">
  <ion-chip *ngFor="let item of operatorList">
    <ion-label>{{ getOperatorInfo(item).Title}}</ion-label>
    <ion-icon name="close-circle" (click)="removeOperator(item)"></ion-icon>
  </ion-chip>
</ng-container>