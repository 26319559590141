<ion-header>
  <ion-toolbar>
    <ion-title>
      Edit Favourite
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="journeys-page ion-padding">

  <div class="banner">
    <h1>{{poi.AddressInfo.Title}}</h1>
    <p>{{poi.AddressInfo.AddressLine1}}</p>
  </div>

  <p>
    Add Charging Location as a Journey Waypoint
  </p>

  <ion-item>
    <ion-label position="stacked">Add to a Journey</ion-label>
    <ion-select [(ngModel)]="selectedJourneyID">
      <ion-select-option value="" checked>Create a New Journey</ion-select-option>
      <ion-select-option *ngFor="let journey of journeyManager.journeys" [value]="journey.ID">{{journey.Title}}</ion-select-option>
    </ion-select>

  </ion-item>
  <ion-item *ngIf="selectedJourneyID">
    <ion-label position="stacked">Journey Stage</ion-label>
    <ion-select [(ngModel)]="selectedStageIndex">
      <ion-select-option value="">Create a Journey Stage</ion-select-option>
      <ion-select-option *ngFor="let stage of journeyManager.getJourneyStages(selectedJourneyID); let i=index;" [value]="i"
        checked>{{stage.Title}}</ion-select-option>
    </ion-select>

  </ion-item>
  <ion-item *ngIf="!selectedJourneyID">
    <ion-label position="stacked">New Journey Name</ion-label>
    <ion-input type="text" [(ngModel)]="newJourneyName"></ion-input>

  </ion-item>
  <ion-item>
    <ion-label position="stacked">Name for this step of your journey</ion-label>
    <ion-input type="text" [(ngModel)]="waypoint.Title"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="stacked">Other Notes</ion-label>
    <ion-textarea [(ngModel)]="waypoint.Notes"></ion-textarea>
  </ion-item>

</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="secondary">
      <ion-button (click)="cancel()">
        <ion-icon name="close" slot="start"></ion-icon>
        Cancel

      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button (click)="add()">
        Add
        <ion-icon name="add" slot="end"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>