<ion-header>
		<div class="search-row">
			<ion-buttons slot="start">
				<ion-menu-button></ion-menu-button>
			</ion-buttons>

			<ion-searchbar autocapitalize="on" autocomplete="on" [(ngModel)]="searchKeyword" (ionInput)="search($event)"
						   [placeholder]="'ocm.search.performSearch' | translate" [debounce]="500">
			</ion-searchbar>

			<ion-buttons slot="end">
				<ion-button (click)="locateUser()">
					<ion-icon *ngIf="!appManager.isRequestInProgress" name="locate" slot="start"></ion-icon>
					<ion-spinner *ngIf="appManager.isRequestInProgress"></ion-spinner>
				</ion-button>

				<ion-button (click)="openSearchOptions()" [color]="appManager.searchSettings.HasActiveFilters ? 'danger' : 'default'">
					<ion-icon name="options" slot="end"></ion-icon>
				</ion-button>

				<ion-button (click)="openSideView()" *ngIf="sideViewAvailable && poiViewMode === 'modal'">
					<ion-icon name="car" title="Route" slot="end"></ion-icon>
				</ion-button>
			</ion-buttons>
		</div>
</ion-header>

<ion-content class="search-page">
	<place-search #placeSearchMapPOI (placeChanged)="placeSelected($event)"></place-search>

	<ng-container *ngIf="appManager.isOffline">
		<div style="position: absolute;">
			<p>There was a problem communicating with O2Run. Please check your data connection.</p>
		</div>
	</ng-container>

	<div id="map-canvas" style="height:100%;width:100%;min-height:100%;"></div>
</ion-content>
