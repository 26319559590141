<ion-header [ngStyle]="{
    '-webkit-padding-start': 'var(--padding-start)',
    'padding-inline-start': 'var(--padding-start)',
    '-webkit-padding-end': 'var(--padding-end)',
    'padding-inline-end': 'var(--padding-end)',
    'padding-top': 'var(--padding-top)',
    'padding-bottom': 'var(--padding-bottom)',
    'display': 'flex',
    'position': 'relative',
    '-ms-flex-direction': 'row',
    'flex-direction': 'row',
    '-ms-flex-align': 'center',
    'align-items': 'center',
    '-ms-flex-pack': 'justify',
    'justify-content': 'space-between',
    'width': '100%',
    'min-height': 'var(--min-height)',
    'contain': 'content',
    'overflow': 'hidden',
    'z-index': '10',
    '-webkit-box-sizing': 'border-box',
    'box-sizing': 'border-box',
    'background': '#011A26',
    'height': '56px',
    'color': 'white',
  }">
    <ion-toolbar [ngStyle]="{'background': '#011A26'}">
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>
            <img src="assets/images/icons/branding/o2run-logo.png" />
        </ion-title>
        <ion-buttons slot="end">
            <ion-button routerLink="/search">Home</ion-button>
            <ion-button routerLink="/about">About</ion-button>
            <ng-container *ngIf="!isUserAuthenticated()">
                <ion-item (click)="signIn()">
                    <ion-label>
                        {{'ocm.general.signIn' | translate }}
                    </ion-label>
                </ion-item>
            </ng-container>

            <ng-container *ngIf="isUserAuthenticated()" >

                <ion-item (click)="profile()">
                    <ion-label>
                        {{'ocm.navigation.profile.sectionTitle' | translate }}
                    </ion-label>
                </ion-item>
                <ion-item (click)="signOut()">
                    <ion-label>
                        {{'ocm.general.signOut' | translate }}
                        <br>
                        <small style="color:#c0c0c0">
                            {{getUserName()}}
                        </small>
                    </ion-label>
                </ion-item>

            </ng-container>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
