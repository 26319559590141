<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button (click)="dismiss()"></ion-back-button>
        </ion-buttons>
        <ion-title>
            Journeys
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="journeys-page">

    <div class="ion-padding">

        <p>
            You can create and plan journeys to group your favourite charging routes together.
        </p>

        <p *ngIf="journeyManager.journeys==null || journeyManager.journeys.length==0">
            You have no journeys yet. Start by browsing to a charging location, then add it as Favourite to start a new
            Journey.

        </p>

    </div>

    <ion-card *ngFor="let journey of journeyManager.journeys">

        <ion-card-content>
            <ion-toolbar primary>

                <ion-title>
                    {{journey.Title}}</ion-title>
                <ion-buttons slot="primary">
                    <ion-button (click)="deleteJourney(journey.ID)">
                        <ion-icon name="close" slot="icon-only"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>

            <p>{{journey.Notes}}</p>

            <ion-list *ngFor="let s of journey.Stages">
                <ion-list-header style="font-size:1.5rem;">
                    {{s.Title}}

                    <ion-icon name="car"></ion-icon> --
                    <ion-icon name="time"></ion-icon> --
                    <ion-icon name="flash"></ion-icon> --

                </ion-list-header>

                <ion-item *ngFor="let w of s.WayPoints">

                    <h2>{{w.Title}}</h2>
                    <ion-thumbnail slot="start">
                        <ion-icon name="caret-down" style="font-size:4rem;"></ion-icon>
                    </ion-thumbnail>

                    <p>{{w.Notes}}</p>

                    <div *ngFor="let p of w.PoiList" style="text-align:right;width:100%;">
                        <div *ngIf="p.Poi">
                            <div (click)="viewPOIDetails(p.Poi)" tappable>
                                <p *ngIf="p.Poi.AddressInfo.AddressLine1">
                                    {{p.Poi.AddressInfo.AddressLine1}}</p>
                                <p *ngIf="p.Poi.AddressInfo.AddressLine2">
                                    {{p.Poi.AddressInfo.AddressLine2}}</p>
                                <p *ngIf="p.Poi.AddressInfo.Town">
                                    {{p.Poi.AddressInfo.Town}}</p>
                                <p *ngIf="p.Poi.AddressInfo.StateOrProvince">
                                    {{p.Poi.AddressInfo.StateOrProvince}}</p>
                                <p *ngIf="p.Poi.AddressInfo.Postcode">
                                    {{p.Poi.AddressInfo.Postcode}}</p>

                            </div>
                            <ion-button clear item-right (click)="launchNavigation(p.Poi)">
                                <ion-icon name="navigate" slot="start"></ion-icon>
                                Navigate
                            </ion-button>

                        </div>
                    </div>

                </ion-item>

            </ion-list>

        </ion-card-content>

    </ion-card>

</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()">
                <ion-icon name="close" slot="start"></ion-icon> Close
            </ion-button>

        </ion-buttons>
    </ion-toolbar>
</ion-footer>