<ion-header>
    <ion-toolbar color="primary">
        <ion-title>
            {{poi.AddressInfo.Title}}
            <small>OCM-{{poi.ID}}</small>
        </ion-title>
        <ion-buttons slot="start">
            <ion-button (click)="close()">
                <ion-icon name="close" slot="icon-only"></ion-icon>

            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="poi-details">
    <poi-details [poi]="poi"></poi-details>
</ion-content>