import { Router } from '@angular/router';
import { Logging } from './../../services/Logging';
import { AppManager } from './../../services/AppManager';
import { TranslateService } from '@ngx-translate/core';

import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';

@Component({
    templateUrl: 'signin.html'
})
export class SignInPage {
    email: string;
    password: string;

    constructor(
        public appManager: AppManager,
        public modalController: ModalController,
        public translate: TranslateService,
        public alertController: AlertController,
        public loadingController: LoadingController,
        public logging: Logging,
        public router: Router,
        private location: Location
    ) {
        this.email = '';
    }

    cancelSignIn() {
        this.modalController.dismiss();
    }

    async performSignIn() {
        const loading = await this.loadingController.create({
            message: 'Signing In...'
        });
        await loading.present();

        let signInFailed = false;

        try {
            const signInResult = await this.appManager.api.performSignIn(this.email, this.password);
            loading.dismiss();

            if (signInResult) {
                localStorage.setItem('authResponse', JSON.stringify(signInResult));
                this.appManager.isUserAuthenticated(true);
            } else {
                signInFailed = true;
            }

            this.modalController.dismiss();

            if (!signInFailed) {
                this.appManager.analytics.appEvent("Profile", "SignedIn");
            }
        } catch (err) {
            signInFailed = true;
            loading.dismiss();

            const alert = await this.alertController.create({
                header: 'O2Run',
                subHeader: 'Email or Password not recognised',
                buttons: ['Ok']
            });
            await alert.present();

            // Enhanced error logging
            this.logging.log(`Error logging in: ${err.message}`, err);

        }

        if (signInFailed) {
            // Handle failed sign-in
        }
    }

}
