<div class="place-search" *ngIf="placeSearchActive">

  <div *ngIf="searchInProgress">
    <ion-spinner></ion-spinner>
  </div>

  <ion-list>
    <ion-item *ngFor="let item of placeList" (click)="placeSelected(item)">

      <ion-avatar item-left>
        <ion-icon name="locate"></ion-icon>
      </ion-avatar>
      <ion-label>
        <p>{{item.Address}}</p>
      </ion-label>
    </ion-item>

    <ion-item>

      <p class="place-attribution">
        {{placeAttribution}}
      </p>

    </ion-item>
  </ion-list>

</div>