<ion-header>
  <ion-toolbar>
    <ion-title>
      Edit Layer
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <p>POI layers are used to preview data from other Open Data sources. </p>
  <p>Select a POI layer JSON file:
  </p>
  <div>

    <label class="upload">

      <ion-button>
        <ion-icon name="file" aria-label="file" slot="start"></ion-icon>
        <ion-label>Choose File..</ion-label>
      </ion-button>
      <input type="file" required id="file-upload" (change)="previewFile()"/>

    </label>
  </div>

  <div *ngIf="fileData && fileData.length>0">
    Items Parsed: {{fileData.length}}
    <app-poi-list [poiList]="fileData"></app-poi-list>
  </div>

</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="secondary">
      <ion-button (click)="cancel()">
        <ion-icon name="close" slot="start"></ion-icon>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button (click)="add()">
        Add

        <ion-icon name="checkmark-circle-outline" slot="end"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>