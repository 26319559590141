<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      Add Comment
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="comment-page ion-padding">

  <div class="banner">
    <h1>{{poi.AddressInfo.Title}}</h1>
    <p>{{poi.AddressInfo.AddressLine1}}</p>
  </div>
  <p class="ion-padding">Recent Check-ins are a big help to other drivers when planning their journey. Tell everyone what you
    thought about this
    charging location. </p>

  <ion-list>

    <ion-item>
      <ion-label position="stacked">Comment Type</ion-label>
      <ion-select [(ngModel)]="commentModel.CommentTypeID">
        <ion-select-option *ngFor="let item of commentTypes" [value]="item.ID">{{item.Title}}</ion-select-option>
      </ion-select>

    </ion-item>

    <ion-item>
      <ion-label position="stacked">What Did You Think?</ion-label>
      <ion-input type="text" [(ngModel)]="commentModel.Comment" placeholder="(optional comment)"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">Did You Successfully Charge?</ion-label>
      <ion-select [(ngModel)]="commentModel.CheckinStatusTypeID">
        <ion-select-option *ngFor="let item of checkinTypes" [value]="item.ID">{{item.Title}}</ion-select-option>
      </ion-select>

    </ion-item>

    <ion-item>
      <ion-label position="stacked">Your Rating</ion-label>

      <ion-select [(ngModel)]="commentModel.Rating">
        <ion-select-option value="5">5 - Excellent</ion-select-option>
        <ion-select-option value="4">4 - Good</ion-select-option>
        <ion-select-option value="3">3 - Average</ion-select-option>
        <ion-select-option value="2">2 - Not Good</ion-select-option>
        <ion-select-option value="1">1 - Bad</ion-select-option>
        <ion-select-option value="">Not Rated</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="secondary">
      <ion-button (click)="cancel()">
        <ion-icon name="close" slot="start"></ion-icon>
        Cancel

      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button (click)="add()">
        Submit
        <ion-icon name="send" slot="end"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>