<ion-header [ngStyle]="{
    '-webkit-padding-start': 'var(--padding-start)',
    'padding-inline-start': 'var(--padding-start)',
    '-webkit-padding-end': 'var(--padding-end)',
    'padding-inline-end': 'var(--padding-end)',
    'padding-top': 'var(--padding-top)',
    'padding-bottom': 'var(--padding-bottom)',
    'display': 'flex',
    'position': 'relative',
    '-ms-flex-direction': 'row',
    'flex-direction': 'row',
    '-ms-flex-align': 'center',
    'align-items': 'center',
    '-ms-flex-pack': 'justify',
    'justify-content': 'space-between',
    'width': '100%',
    'min-height': 'var(--min-height)',
    'contain': 'content',
    'overflow': 'hidden',
    'z-index': '10',
    '-webkit-box-sizing': 'border-box',
    'box-sizing': 'border-box',
    'background': '#011A26',
    'height': '56px',
    'color': 'white'
  }">
        <ion-title>
            {{'ocm.general.signIn' |translate}}
        </ion-title>
</ion-header>

<ion-content class="signin-page ion-padding">

    <ion-avatar [ngStyle]="{   width: '42%', marginLeft: '10px', '--border-radius': 0}">
        <img src="assets/images/icons/branding/logo2.webp"/>
    </ion-avatar>
    <p class="ion-padding">
        Sign in using your Kaayoo account.
    </p>
    <ion-list>
        <ion-item>
            <ion-label position="floating">Email</ion-label>
            <ion-input type="text" [(ngModel)]="email"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label position="floating">Password</ion-label>
            <ion-input type="password" [(ngModel)]="password"></ion-input>
        </ion-item>

    </ion-list>

    <p>or <a [ngStyle]="{   'cursor': 'pointer'}" (click)="appManager.launchWebPage('https://map.o2run.com/loginprovider/register')">Register as New User</a>
    or <a [ngStyle]="{   'cursor': 'pointer'}" (click)="appManager.launchWebPage('https://map.o2run.com/loginprovider/passwordreset')">Reset your password</a>
    </p>

</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="secondary">
            <ion-button (click)="cancelSignIn()">
                <ion-icon name="close" slot="start"></ion-icon> Cancel</ion-button>

        </ion-buttons>
        <ion-buttons slot="primary">

            <ion-button (click)="performSignIn()" type="submit" color="primary">
                <ion-icon name="log-in" slot="start"></ion-icon>
                {{'ocm.general.signIn' | translate}}</ion-button>

        </ion-buttons>

    </ion-toolbar>
</ion-footer>
