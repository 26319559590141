<div *ngIf="poi!=null && poi.AddressInfo!=null">

  <div class="banner" [style.background-image]="backdropImage?'url(' + backdropImage + ')':''">

    <div class="banner-content">
      <h2>
        {{poi.AddressInfo.Title}}
      </h2>
      <div>
        <small>OCM-{{poi.ID}}</small>
      </div>
      <div *ngIf="connectionSummary">
        <small>{{connectionSummary}}</small>
      </div>
      <div *ngIf="poi.OperatorInfo">
        <small>{{poi.OperatorInfo.Title}}</small>
      </div>
      <div *ngIf="poi.SubmissionStatusTypeID==1">
        <small>[Submission Awaiting Review]</small>
      </div>

      <ion-badge color="primary" title="Average User Rating">{{avgRating | number:'1.1-1'}}</ion-badge>
    </div>
    <ion-fab vertical="top" horizontal="end" slot="fixed">
      <ion-fab-button>
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
      <ion-fab-list side="bottom">
        <ion-fab-button (click)="addComment()" title="Add a comment or charging check-in">
          <ion-icon name="chatbubbles"></ion-icon>
        </ion-fab-button>
        <ion-fab-button (click)="addMedia()" title="Add a Photo">
          <ion-icon name="camera"></ion-icon>
        </ion-fab-button>
        <ion-fab-button (click)="addFavourite()" title="Add to Favourites">
          <ion-icon name="star"></ion-icon>
        </ion-fab-button>
        <ion-fab-button (click)="edit()" title="Submit an Edit">
          <ion-icon name="create"></ion-icon>
        </ion-fab-button>
        <ion-fab-button (click)="refresh()" title="Refresh">
          <ion-icon name="refresh"></ion-icon>
        </ion-fab-button>
      </ion-fab-list>

    </ion-fab>
  </div>
  <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
    <ion-refresher-content pullingIcon="caret-down" pullingText="Pull to refresh" refreshingSpinner="circles"
      refreshingText="Refreshing..."></ion-refresher-content>
  </ion-refresher>
  <ion-toolbar>
    <ion-buttons slot="start">

      <ng-container *ngIf="selectedTab=='location'">
        <ion-button size="small" (click)="addFavourite()" title="Add to Favourites">
          <ion-icon name="star" slot="start"></ion-icon>
          <ion-label>Favourite</ion-label>
        </ion-button>
        <ion-button size="small" (click)="edit()" title="Submit an Edit">
          <ion-icon name="create" slot="start"></ion-icon>
          <ion-label>Edit</ion-label>
        </ion-button>
      </ng-container>
    </ion-buttons>

    <ion-buttons slot="end">

      <ng-container *ngIf="selectedTab=='location'">
        <ion-button size="small" (click)="launchNavigation()" title="Navigate to this location">
          <ion-icon name="navigate" slot="start"></ion-icon>
          <ion-label>Navigate</ion-label>
        </ion-button>
      </ng-container>

      <ng-container *ngIf="selectedTab=='comments'">
        <ion-button size="small" (click)="addComment()" title="Add a comment or charging check-in">
          <ion-icon name="chatbubbles" slot="start"></ion-icon>
          <ion-label>Add Comment</ion-label>
        </ion-button>
      </ng-container>

      <ng-container *ngIf="selectedTab=='media'">
        <ion-button size="small" (click)="addMedia()" title="Add a Photo">
          <ion-icon name="camera" slot="start"></ion-icon>
          <ion-label> Add Photo</ion-label>
        </ion-button>
      </ng-container>
    </ion-buttons>
  </ion-toolbar>


  <ion-segment [(ngModel)]="selectedTab">
    <ion-segment-button value="location" selected>
      <ion-label>
        Details
      </ion-label>
    </ion-segment-button>

    <ion-segment-button value="comments">
      <ion-label>Comments ({{poi.UserComments?poi.UserComments.length:0}})</ion-label>

    </ion-segment-button>
    <ion-segment-button value="media">
      <ion-label> Photos ({{poi.MediaItems?poi.MediaItems.length:0}})</ion-label>
    </ion-segment-button>
  </ion-segment>


  <div *ngIf="selectedTab=='location'">

    <ion-card *ngIf="poi.AddressInfo">

      <ion-card-header>
        <ion-card-subtitle>
          {{ 'ocm.details.location.sectionTitle' | translate }}
        </ion-card-subtitle>

      </ion-card-header>
      <ion-card-content>

        <ion-grid>
          <ion-row>
            <ion-col>

              <ion-label>
                {{ 'ocm.details.location.address' | translate }}</ion-label>
              <div class="details">
                <div *ngIf="poi.AddressInfo.AddressLine1">
                  {{poi.AddressInfo.AddressLine1}}</div>
                <div *ngIf="poi.AddressInfo.AddressLine2">
                  {{poi.AddressInfo.AddressLine2}}</div>
                <div *ngIf="poi.AddressInfo.Town">
                  {{poi.AddressInfo.Town}}</div>
                <div *ngIf="poi.AddressInfo.StateOrProvince">
                  {{poi.AddressInfo.StateOrProvince}}</div>
                <div *ngIf="poi.AddressInfo.Postcode">
                  {{poi.AddressInfo.Postcode}}</div>
                <div *ngIf="poi.AddressInfo.Country">
                  {{poi.AddressInfo.Country.Title}}</div>
              </div>

            </ion-col>
            <ion-col>
              <p *ngIf="poi.AddressInfo.AccessComments">
                {{poi.AddressInfo.AccessComments}}
              </p>

              <p *ngIf="poi.AddressInfo.ContactTelephone1">
                <ion-icon name="call"></ion-icon>
                <span class="details">{{poi.AddressInfo.ContactTelephone1}}</span>
              </p>
              <p *ngIf="poi.AddressInfo.ContactTelephone2">
                <ion-icon name="call"></ion-icon>
                <span class="details">{{poi.AddressInfo.ContactTelephone2}}</span>
              </p>

              <p *ngIf="poi.AddressInfo.ContactEmail">
                <ion-icon name="mail"></ion-icon>
                <span class="details">{{poi.AddressInfo.ContactEmail}}</span>
              </p>
              <p *ngIf="poi.AddressInfo.RelatedURL">
                <ion-icon name="globe"></ion-icon>
                <span class="details">
                  <a target="_system" href="{{poi.AddressInfo.RelatedURL}}">{{poi.AddressInfo.RelatedURL}}</a>
                </span>
              </p>

            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>

              <ion-label class="details-minor">
                Lat/Long: {{poi.AddressInfo.Latitude | number:'1.1-6'}}
                , {{poi.AddressInfo.Longitude | number:'1.1-6'}}
              </ion-label>

            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-card-content>

    </ion-card>

    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>
          {{ 'ocm.details.equipment.sectionTitle' | translate }}
        </ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>

        <p *ngIf="poi.NumberOfPoints!=null">
          <span class="label">{{ 'ocm.details.numberOfPoints' | translate }}:</span>
          <span class="details">{{poi.NumberOfPoints}}</span>
        </p>

        <app-equipment-details [item]="poi"></app-equipment-details>

      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>Usage Restrictions</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <p *ngIf="poi.StatusType!=null">
          <span class="label">{{ 'ocm.details.operationalStatus' | translate }}:</span>
          <span class="details">
            {{poi.StatusType.Title}}
          </span>
        </p>
        <p *ngIf="poi.UsageType!=null">
          <span class="label">{{ 'ocm.details.usageType' | translate }}:</span>
          <span class="details">{{poi.UsageType.Title}}</span>
        </p>
        <p *ngIf="poi.UsageCost!=null">
          <span class="label">{{ 'ocm.details.usageCost' | translate }}:</span>
          <span class="details">
            {{poi.UsageCost}}</span>
        </p>
        <p *ngIf="poi.GeneralComments!=null"><br>{{ 'ocm.details.generalComments' | translate }}: <br>
          <span class="details">{{poi.GeneralComments}}</span>
        </p>

      </ion-card-content>
    </ion-card>

    <ion-card *ngIf="poi.OperatorInfo">
      <ion-card-header>
        <ion-card-subtitle>
          {{ 'ocm.details.operator.sectionTitle' | translate }}
        </ion-card-subtitle>

      </ion-card-header>
      <ion-card-content>
        <div class="details">
          <p class="ion-padding-left">
            {{poi.OperatorInfo.Title}}
          </p>
          <p *ngIf="poi.OperatorInfo.WebsiteURL">
            <ion-icon name="link"></ion-icon>
            {{poi.OperatorInfo.WebsiteURL}}
          </p>
          <p *ngIf="poi.OperatorInfo.ContactEmail!=null">
            <ion-icon name="mail"></ion-icon>
            {{poi.OperatorInfo.ContactEmail}}
          </p>
          <p
            *ngIf="poi.OperatorInfo.FaultReportEmail!=null && poi.OperatorInfo.FaultReportEmail!=poi.OperatorInfo.ContactEmail">
            <ion-icon name="mail"></ion-icon>
            {{poi.OperatorInfo.FaultReportEmail}}
          </p>
        </div>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-header>

        {{ 'ocm.details.advancedDetails' | translate }}
      </ion-card-header>
      <ion-card-content>

        <div *ngIf="poi.DataProvider" class="details">
          <h3>
            {{ 'ocm.details.dataProvider.sectionTitle' | translate }}
          </h3>

          <p>{{poi.DataProvider?.Title}}</p>
          <a *ngIf="poi.DataProvider.WebsiteURL" (click)="launchURL(poi.DataProvider.WebsiteURL)"
            href="javascript:void(0)">{{poi.DataProvider.WebsiteURL}}</a>

          <p>{{poi.DataProvider?.License}}</p>
        </div>
      </ion-card-content>
    </ion-card>

  </div>

  <div *ngIf="selectedTab=='comments'">

    <ng-container *ngIf="poi._hasComments==true">
      <ion-card *ngFor="let item of poi.UserComments">
        <ion-item>
          <ion-avatar slot="start">
            <img *ngIf="item.User && item.User.ProfileImageURL" src="{{item.User?.ProfileImageURL}}" />
          </ion-avatar>
          <ion-label>
            <span *ngIf="item.User">{{item.User.Username}}</span>
            <span *ngIf="!item.User">{{item.Username}}</span>
          </ion-label>
          <ion-label slot="end">
            {{item.DateCreated | date }}
          </ion-label>
        </ion-item>

        <ion-card-content>

          <p>{{item.Comment}}</p>
          <p *ngIf="item.CommentTypeID!=10" class="details">
            {{item.CommentType?.Title}}

          </p>
          <p *ngIf="item.Rating">
            <span class="label">Rating:</span>
            <span class="details">{{item.Rating}} out of 5</span>
          </p>
          <p *ngIf="item.CheckinStatusType">
            <ng-container *ngIf="item.CheckinStatusType.IsPositive===true">
              <ion-icon name="checkmark-circle" color="success" style="font-size:2em;"></ion-icon>
            </ng-container>
            <ng-container *ngIf="item.CheckinStatusType.IsPositive===false">
              <ion-icon name="close-circle" color="danger"></ion-icon>
            </ng-container>
            {{item.CheckinStatusType.Title}}
          </p>

        </ion-card-content>
      </ion-card>
    </ng-container>

    <ng-container *ngIf="poi._hasComments==false">
      <ion-card>
        <ion-card-content>

          <p>
            {{ 'ocm.details.commentsAndRatings.addPrompt' | translate }}
          </p>


        </ion-card-content>
      </ion-card>
    </ng-container>

  </div>
  <div *ngIf="selectedTab=='media'">

    <div *ngIf="poi._hasPhotos">

      <ion-card *ngFor="let item of poi.MediaItems">
        <img src="{{item.ItemMediumURL}}" />
        <ion-item>
          <ion-avatar slot="start">
            <img *ngIf="item.User && item.User.ProfileImageURL" src="{{item.User?.ProfileImageURL}}" />
          </ion-avatar>
          <ion-label>
            <span *ngIf="item.User">{{item.User.Username}}</span>
            <span *ngIf="!item.User">{{item.Username}}</span>
          </ion-label>
          <ion-label slot="end">
            {{item.DateCreated | date }}
          </ion-label>
        </ion-item>

        <ion-card-content>
          <p>{{item.Comment}}</p>
        </ion-card-content>

      </ion-card>
    </div>

    <ng-container *ngIf="poi._hasPhotos==false">
      <ion-card>
        <ion-card-content>
          <p>
            {{ 'ocm.details.mediaItems.addPrompt' | translate }}
          </p>
        </ion-card-content>
      </ion-card>
    </ng-container>

  </div>
  <p *ngIf="appManager.isDebugMode==true" style="white-space: pre-wrap;">
    {{json}}</p>

</div>
<div *ngIf="poi==null || poi.AddressInfo==null">
  <h2>POI Not Found</h2>
  <p>The POI details could not be loaded.</p>
</div>